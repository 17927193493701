import React, { useState, useContext, useEffect, useRef } from 'react';
import BlockQuote from '../../BlockQuote';
import trashIcon from '../../../images/icons/trash.svg';

import { functions } from '../../../api/firebase';
import { httpsCallable } from 'firebase/functions';

// markup
const ListPaymentMethods = (props) => {
  const { children } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [removingPaymentMethod, setRemovingPaymentMethod] = useState(null);

  const listPaymentMethods = httpsCallable(functions, 'listPaymentMethods');
  const detachPaymentMethod = httpsCallable(functions, 'detachPaymentMethod');

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const loadPaymentMethods = (e) => {
    setLoading(true);
    setError(null);

    return listPaymentMethods()
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        if (!data.success) {
          setError('Something went wrong please try again later.');
        } else {
          setPaymentMethods(data.payment_methods.data);
        }

        setLoading(false);
        return;
      })
      .catch((error) => {
        setLoading(false);
        console.log('ERR', error);
        setError('Something went wrong please try again later.');
      });
  };

  const removePaymentMethod = (payment_method) => {
    if (removingPaymentMethod) {
      return;
    }

    setRemovingPaymentMethod(payment_method);
    setError(null);

    return detachPaymentMethod({ payment_method: payment_method })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        if (!data.success) {
          setError('Something went wrong please try again later.');
          setRemovingPaymentMethod(null);
        } else {
          // console.log(data)
          return loadPaymentMethods().then(() => {
            setRemovingPaymentMethod(null);
          });
        }

        return;
      })
      .catch((error) => {
        setRemovingPaymentMethod(null);
        console.log('ERR', error);
        setError('Something went wrong please try again later.');
      });
  };

  return (
    <div className="flex flex-col items-center w-full space-y-5">
      {paymentMethods && (
        <>
          {paymentMethods.map((paymentMethod, index) => (
            <div
              key={`pm-` + index}
              className="relative px-8 py-5 text-sm border rounded-md bg-racqy-settings-panel border-racqy-divider text-racqy-text group"
            >
              <table className="font-mono">
                <tbody>
                  <tr>
                    <td className="px-1 text-right whitespace-nowrap">
                      Issuer:
                    </td>
                    <td className="px-1 capitalize whitespace-nowrap">
                      {paymentMethod.card.brand}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-1 text-right whitespace-nowrap">Name:</td>
                    <td className="px-1 whitespace-nowrap ">
                      {paymentMethod.billing_details.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-1 text-right whitespace-nowrap">
                      Card number:
                    </td>
                    <td className="px-1 whitespace-nowrap ">{`**** **** **** ${paymentMethod.card.last4}`}</td>
                  </tr>
                  <tr>
                    <td className="px-1 text-right whitespace-nowrap">
                      Expires:
                    </td>
                    <td className="px-1 whitespace-nowrap ">{`${paymentMethod.card.exp_month
                      .toString()
                      .padStart(2, '0')}/${paymentMethod.card.exp_year}`}</td>
                  </tr>
                </tbody>
              </table>
              <div
                className="absolute hidden font-sans text-xs text-red-400 rounded-md cursor-pointer group-hover:flex bottom-3 right-3 active:ring-offset-2 active:ring-2 active:ring-racqy-blue"
                onClick={(e) => {
                  removePaymentMethod(paymentMethod.id);
                }}
              >
                Remove
              </div>
              {removingPaymentMethod &&
                removingPaymentMethod == paymentMethod.id && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
                    <div className="flex items-center">
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-racqy-text animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <div className="text-base">Removing...</div>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </>
      )}

      {!loading && !paymentMethods.length && (
        <div>
          <h3 className="text-sm font-medium text-gray-900">
            No payment methods.
          </h3>
        </div>
      )}

      {loading && (
        <div className="flex items-center">
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-racqy-text animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <div className="text-base">Loading payment methods...</div>
        </div>
      )}

      {error && (
        <BlockQuote type={'error'}>{error && <p>{error}</p>}</BlockQuote>
      )}
    </div>
  );
};

export default ListPaymentMethods;
