import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import '../../styles/template.css';
import ActionButton from '../../components/ActionButton';
import BlockQuote from '../../components/BlockQuote';
import AddPaymentMethod from '../../components/Stripe/AddPaymentMethod';
import ListPaymentMethods from '../../components/Stripe/ListPaymentMethods';
import worldIcon from '../../images/icons/world.svg';
import { auth } from '../../api/firebase';
import { onAuthStateChanged, updateProfile, updateEmail } from 'firebase/auth';
import Layout from '../../components/Layout';
import { useAuthContext } from '../../contexts/auth';
import { useDispatch } from 'react-redux';
import { setLoginModalOpen } from '../../redux/slices/loaderSlice';

const Page = ({ location }) => {
  const redirectPath = '/profile/';

  const { user, checked, isRedirectURL } = useAuthContext();

  const dispatch = useDispatch();

  const [profileUser, setProfileUser] = useState(false);
  const [displayName, setDisplayName] = useState(
    profileUser ? profileUser?.displayName : ''
  );
  const [email, setEmail] = useState(
    profileUser ? profileUser?.displayName : ''
  );
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hydrated, setHydrated] = useState(false);

  const [editingProfile, setEditingProfile] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [error, setError] = useState(null);

  const handlesProfile = true;
  const handlesBilling = true;
  const handlesPaymentMethod = true;
  const handlesReceipts = false;

  useEffect(() => {
    if (!user && checked) {
      if (isRedirectURL) {
        dispatch(setLoginModalOpen(true));
        navigate(`/?required_signin=true&redirect=${redirectPath}`);
      } else {
        dispatch(setLoginModalOpen(false));
        navigate('/');
      }
    }
  }, [checked, user, isRedirectURL]);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setProfileUser(currentUser);

        if (!hydrated) {
          setDisplayName(currentUser.displayName || '');
          setEmail(currentUser.email || '');
          setHydrated(true);
        }

        // Add Authorization to the API-client
        // user.getIdToken()
        //     .then((token) => {
        //         client.defaults.headers['Authorization'] = token
        //         // Make request for user info
        //         return client.get('v1/user-info')
        //     })
        //     .then((data) => {
        //         console.log('data from API', data)
        //     })
      } else {
        // User is signed out
        setProfileUser(false);
      }
    });
  }, []);

  const saveProfile = (e) => {
    setSavingProfile(true);
    setError(null);

    updateProfile(profileUser, {
      displayName: displayName,
    })
      .then(() => {
        // Check if the email should be updated
        if (email != profileUser?.email) {
          return updateEmail(profileUser, email);
        }
        return;
      })
      .then(() => {
        // Profile updated!
        // ...
        setSavingProfile(false);
        setEditingProfile(false);
      })
      .catch((error) => {
        // An error occurred
        // ...
        console.log(error);
        if (error.code == 'auth/invalid-email') {
          setError('Invalid email address');
        } else {
          // Last resort/standard error
          setError('An error occured!');
        }
        setSavingProfile(false);
      });
  };

  const cancelEditingProfile = (e) => {
    setEditingProfile(false);
    setDisplayName(profileUser?.displayName || '');
    setEmail(profileUser?.email || '');
  };

  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Profile</title>
      </Helmet>
      <main id="profile" className="mt-[60px] py-16">
        <div className="w-full max-w-[1100px] mx-auto">
          <div className="flex flex-col space-y-12">
            {/* Profile settings */}
            {handlesProfile && (
              <div className="px-16 py-12 bg-racqy-settings-panel rounded-3xl">
                <div className="w-full max-w-4xl space-y-6">
                  <h2 className="text-racqy-text text-2xl sm:text-3xl leading-[1.375]">
                    Account settings
                  </h2>
                  <div className="border-b divide-y divide-racqy-divider border-racqy-divider">
                    <div className="flex flex-row items-center">
                      <span className="w-1/2 text-base sm:text-xl text-racqy-settings-label">
                        Phone
                      </span>
                      <div className="block w-1/2 py-5 text-base border-white px-7 focus:border-racqy-blue focus:ring-racqy-blue sm:text-xl">
                        {profileUser?.phoneNumber}
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <label
                        htmlFor="displayName"
                        className="w-1/2 text-base sm:text-xl text-racqy-settings-label"
                      >
                        Display name
                      </label>
                      <input
                        type="text"
                        name="displayName"
                        id="displayName"
                        value={displayName}
                        onChange={(e) => {
                          setDisplayName(e.target.value);
                        }}
                        className="block w-1/2 py-5 text-base bg-white border-white px-7 focus:border-racqy-blue focus:ring-racqy-blue sm:text-xl"
                        readOnly={!editingProfile}
                      />
                    </div>
                    <div className="flex flex-row items-center">
                      <label
                        htmlFor="email"
                        className="w-1/2 text-base sm:text-xl text-racqy-settings-label"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="block w-1/2 py-5 text-base bg-white border-white px-7 focus:border-racqy-blue focus:ring-racqy-blue sm:text-xl"
                        readOnly={!editingProfile}
                      />
                    </div>
                  </div>

                  {error && (
                    <BlockQuote type="error">
                      <p>{error}</p>
                    </BlockQuote>
                  )}

                  {editingProfile && (
                    <div className="flex space-x-6">
                      <ActionButton
                        title="Cancel"
                        tint="light"
                        onClick={() => cancelEditingProfile()}
                      ></ActionButton>
                      <ActionButton
                        title={
                          savingProfile ? 'Saving changes...' : 'Save changes'
                        }
                        tint="blue"
                        onClick={() => saveProfile()}
                        loading={savingProfile}
                      ></ActionButton>
                    </div>
                  )}
                  {!editingProfile && (
                    <div>
                      <ActionButton
                        title="Edit profile"
                        tint="light"
                        onClick={() => setEditingProfile(true)}
                      ></ActionButton>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Payment method settings */}
            {handlesBilling && (
              <div className="px-16 py-12 bg-racqy-settings-panel rounded-3xl">
                <div className="w-full space-y-6">
                  <h2 className="text-racqy-text text-2xl sm:text-3xl leading-[1.375]">
                    Billing
                  </h2>
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <div className="space-y-6">
                      {handlesPaymentMethod && (
                        <div className="p-8 bg-white border rounded-xl border-racqy-divider">
                          <div className="flex flex-row items-center mb-6">
                            <div className="flex items-center justify-center w-16 h-16 rounded-xl bg-racqy-settings-panel">
                              <img src={worldIcon} className="w-9" />
                            </div>
                            <h3 className="ml-6">Payment methods</h3>
                          </div>
                          <ListPaymentMethods />
                          <div className="flex items-center justify-center mt-6">
                            <AddPaymentMethod />
                          </div>
                        </div>
                      )}
                    </div>
                    {handlesReceipts && (
                      <div>
                        <div className="p-8 bg-white border rounded-xl border-racqy-divider">
                          <div className="flex flex-row items-center">
                            <div className="flex items-center justify-center w-16 h-16 rounded-xl bg-racqy-settings-panel">
                              <img src={worldIcon} className="w-9" />
                            </div>
                            <h3 className="ml-6">Receipts</h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-5">
            <Link
              className="pt-5 underline"
              href="/account_management/account_deletion_request"
            >
              Delete your account
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Page;
