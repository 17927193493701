import React, { useState, useContext, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import BlockQuote from '../../BlockQuote';
import ActionButton from '../../ActionButton';

import { functions } from '../../../api/firebase';
import { httpsCallable } from 'firebase/functions';

// markup
const AddPaymentMethod = (props) => {
  const { children } = props;

  const [error, setError] = useState(false);
  const [creatingCheckoutSession, setCreatingCheckoutSession] = useState(false);
  const [checkoutSessionCreated, setCheckoutSessionCreated] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const createCheckoutSession = httpsCallable(
    functions,
    'createCheckoutSession'
  );

  useEffect(() => {}, []);

  const startCheckoutSession = (e) => {
    setCreatingCheckoutSession(true);
    setRedirecting(false);
    setError(null);

    createCheckoutSession()
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        if (!data.success) {
          setError('Something went wrong please try again later.');
        } else {
          setCheckoutSessionCreated(true);
          setRedirecting(true);

          // Navigate to stripes payment method page
          // navigate(data.session.url)
          window.location.href = data.session.url;
        }
      })
      .catch((error) => {
        setCreatingCheckoutSession(false);
        console.log('ERR', error);
        setError('Something went wrong please try again later.');
      });
  };

  return (
    <div className="flex flex-col items-center w-full space-y-5">
      {!checkoutSessionCreated && (
        <div>
          <ActionButton
            title={
              creatingCheckoutSession
                ? 'Contacting Stripe...'
                : 'Add payment method'
            }
            tint="blue"
            onClick={() => startCheckoutSession()}
            loading={creatingCheckoutSession}
          ></ActionButton>
        </div>
      )}

      {redirecting && (
        <div>
          <ActionButton
            title={'Redirecting...'}
            tint="blue"
            onClick={() => {}}
            loading={true}
          ></ActionButton>
        </div>
      )}

      {error && (
        <BlockQuote type={'error'}>{error && <p>{error}</p>}</BlockQuote>
      )}
    </div>
  );
};

export default AddPaymentMethod;
